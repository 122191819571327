import { Card, Spin, notification } from "antd";
import React from "react";
import {
  ChannelContext,
  ChannelContextProvider,
  ChannelContextType,
} from "../../contexts/channels/ChannelContext";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ChannelModel } from "../../models/ChannelModel";
import "./channel.css";
import {
  DonationSingleCartResultInterface,
  DonationSingleResultInterface,
} from "../../contexts/payment/interfaces/donation-single-result.interface";
import {
  PaymentContext,
  PaymentContextProvider,
  PaymentContextType,
} from "../../contexts/payment/PaymentContext";
import { NotificationPlacement } from "antd/lib/notification";

export interface PaymentInterfaceParam {
  item: ChannelModel;
  encrypt?: string;
  donationResult?: DonationSingleResultInterface;
  donationCartResult?: DonationSingleCartResultInterface;
}

function Channel() {
  return (
    <ChannelContextProvider>
      <PaymentContextProvider>
        <ChannelState />
      </PaymentContextProvider>
    </ChannelContextProvider>
  );
}

function ChannelState() {
  const {
    channels,
    midtransChannels,
    ipaymuChannels,
    walletChannels,
    nicepayChannels,
    ifortepayChannels
  } = React.useContext(ChannelContext) as ChannelContextType;
  const [encrypt, setEncrypt] = React.useState<string>("");
  const [donationResult, setDonationResult] =
    React.useState<DonationSingleResultInterface>(undefined);
  const { getDonationDetailCart, donationDetailCart, donationDetailLoading } =
    React.useContext(PaymentContext) as PaymentContextType;
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement: NotificationPlacement) => {
    api.error({
      message: `Gagal`,
      description: "Anda harus login untuk menggunakan fitur wallet",
      placement,
    });
  };
  const openNotificationTemp = (placement: NotificationPlacement) => {
    api.error({
      message: `Mohon Maaf`,
      description:
        "Untuk keranjang donasi yang lebih dari 1, silahkan gunakan metode pembayaran lain, untuk sementara metode pembayaran Transfer Manual belum bisa digunakan untuk keranjang donasi lebih dari 1.",
      placement,
    });
  };

  React.useEffect(() => {
    if (params) {
      if (params["invoice_code"] !== undefined) {
        getDonationDetailCart(params["invoice_code"] as string);
      }
    }
  }, [params]);

  React.useEffect(() => {
    if (encrypt !== "") {
      getDonationDetailCart(encrypt);
    }
  }, [encrypt]);

  React.useEffect(() => {
    setEncrypt(location.search.replace("?token=", ""));
  }, [location]);

  return (
    <div
      style={{
        margin: 20,
      }}
    >
      {contextHolder}
      <Spin tip="Loading..." spinning={donationDetailLoading}>
        {walletChannels?.length > 0 ? <h3>Wallet</h3> : null}
        {walletChannels.map((item, index) => {
          return (
            <Card
              key={item.code}
              style={{
                marginBottom: 10,
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.preventDefault();

                if (donationDetailCart?.data[0]?.created_by !== "") {
                  let param: PaymentInterfaceParam = {
                    item: item,
                    donationResult: donationDetailCart[0],
                    donationCartResult: donationDetailCart,
                  };

                  history.push({
                    pathname: `/payment-wallet-v2/${encrypt}`,
                    state: param,
                  });
                } else {
                  openNotification("topRight");
                }
              }}
            >
              <Card.Meta
                // avatar={<img src={`/assets/${item.code}.png`} style={{ width: 40, height: 10, objectFit: 'contain' }}/>}
                avatar={<span className={`${item.thumbnail}`}></span>}
                title={item.title}
                description={item.subtitle}
              />
            </Card>
          );
        })}

        {channels?.length > 0 ? <h3>Transfer Manual</h3> : null}
        {channels.map((item, index) => {
          return (
            <Card
              key={item.code}
              style={{
                marginBottom: 10,
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.preventDefault();

                const donationDetailSingle: DonationSingleResultInterface = {
                  status: donationDetailCart?.status,
                  message: donationDetailCart?.message,
                  data: donationDetailCart?.data[0],
                  error: donationDetailCart?.error,
                };

                let param: PaymentInterfaceParam = {
                  item: item,
                  donationResult: donationDetailSingle,
                  donationCartResult: donationDetailCart,
                };

                if (donationDetailCart?.data?.length > 1) {
                  openNotificationTemp("topRight");
                  // history.push({
                  //   pathname: "/payment-cart",
                  //   state: param,
                  // });
                } else {
                  history.push({
                    pathname: "/payment",
                    state: param,
                  });
                }
              }}
            >
              <Card.Meta
                // avatar={<img src={`/assets/${item.code}.png`} style={{ width: 40, height: 10, objectFit: 'contain' }}/>}
                avatar={<span className={`${item.thumbnail}`}></span>}
                title={item.title}
                description={item.subtitle}
              />
            </Card>
          );
        })}

        {midtransChannels?.length > 0 && <h3>Midtrans VA & Merchants</h3>}
        {midtransChannels.map((item, index) => {
          return (
            <Card
              key={index}
              style={{
                marginBottom: 10,
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.preventDefault();

                const donationDetailSingle: DonationSingleResultInterface = {
                  status: donationDetailCart?.status,
                  message: donationDetailCart.message,
                  data: donationDetailCart.data[0],
                  error: donationDetailCart.error,
                };

                let param: PaymentInterfaceParam = {
                  item: item,
                  donationResult: donationDetailSingle,
                  donationCartResult: donationDetailCart,
                };

                if (donationDetailCart.data?.length > 1) {
                  history.push({
                    pathname: "/payment-midtrans-cart",
                    state: param,
                  });
                } else {
                  history.push({
                    pathname: "/payment-midtrans",
                    state: param,
                  });
                }
              }}
            >
              <Card.Meta
                // avatar={<img src={`/assets/${item.code}.png`} style={{ width: 40, height: 10, objectFit: 'contain' }}/>}
                avatar={<span className={`${item.thumbnail}`}></span>}
                title={item.title}
                description={item.subtitle}
              />
            </Card>
          );
        })}

        {ipaymuChannels?.length > 0 && <h3>i-Paymu VA & Merchants</h3>}
        {ipaymuChannels.map((item, index) => {
          return (
            <Card
              key={index}
              style={{
                marginBottom: 10,
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.preventDefault();

                const donationDetailSingle: DonationSingleResultInterface = {
                  status: donationDetailCart?.status,
                  message: donationDetailCart.message,
                  data: donationDetailCart.data[0],
                  error: donationDetailCart.error,
                };

                let param: PaymentInterfaceParam = {
                  item: item,
                  donationResult: donationDetailSingle,
                  donationCartResult: donationDetailCart,
                };

                if (donationDetailCart.data?.length > 1) {
                  history.push({
                    pathname: "/payment-ipaymu-cart",
                    state: param,
                  });
                } else {
                  history.push({
                    pathname: "/payment-ipaymu",
                    state: param,
                  });
                }
              }}
            >
              <Card.Meta
                // avatar={<img src={`/assets/${item.code}.png`} style={{ width: 40, height: 10, objectFit: 'contain' }}/>}
                avatar={<span className={`${item.thumbnail}`}></span>}
                title={item.title}
                description={item.subtitle}
              />
            </Card>
          );
        })}

        {nicepayChannels?.length > 0 && <h3>NICEPAY VA & Merchants</h3>}
        {nicepayChannels.map((item, index) => {
          return (
            <Card
              key={index}
              style={{
                marginBottom: 10,
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.preventDefault();

                const donationDetailSingle: DonationSingleResultInterface = {
                  status: donationDetailCart?.status,
                  message: donationDetailCart.message,
                  data: donationDetailCart.data[0],
                  error: donationDetailCart.error,
                };

                let param: PaymentInterfaceParam = {
                  item: item,
                  donationResult: donationDetailSingle,
                  donationCartResult: donationDetailCart,
                };

                if (donationDetailCart.data?.length > 1) {
                  history.push({
                    pathname: "/payment-nicepay-cart",
                    state: param,
                  });
                } else {
                  history.push({
                    pathname: "/payment-nicepay",
                    state: param,
                  });
                }
              }}
            >
              <Card.Meta
                // avatar={<img src={`/assets/${item.code}.png`} style={{ width: 40, height: 10, objectFit: 'contain' }}/>}
                avatar={<span className={`${item.thumbnail}`}></span>}
                title={item.title}
                description={item.subtitle}
              />
            </Card>
          );
        })}

        {ifortepayChannels?.length > 0 && <h3>iFortePay</h3>}
        {ifortepayChannels.map((item, index) => {
          return (
            <Card
              key={index}
              style={{
                marginBottom: 10,
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.preventDefault();

                const donationDetailSingle: DonationSingleResultInterface = {
                  status: donationDetailCart?.status,
                  message: donationDetailCart.message,
                  data: donationDetailCart.data[0],
                  error: donationDetailCart.error,
                };

                let param: PaymentInterfaceParam = {
                  item: item,
                  donationResult: donationDetailSingle,
                  donationCartResult: donationDetailCart,
                };

                if (donationDetailCart.data?.length > 1) {
                  history.push({
                    pathname: "/payment-ifortepay-cart",
                    state: param,
                  });
                } else {
                  history.push({
                    pathname: `/payment-ifortepay`,
                    state: param,
                  });
                }
              }}
            >
              <Card.Meta
                // avatar={<img src={`/assets/${item.code}.png`} style={{ width: 40, height: 10, objectFit: 'contain' }}/>}
                avatar={<span className={`${item.thumbnail}`}></span>}
                title={item.title}
                description={item.subtitle}
              />
            </Card>
          );
        })}
      </Spin>
    </div>
  );
}

export default Channel;
