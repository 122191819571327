import { Affix, PageHeader } from "antd";
import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import Channel from "./pages/channels/Channel";
import Payment from "./pages/payment/Payment";
import ArrowLeftOutlined from "@ant-design/icons/ArrowLeftOutlined";
import PaymentMidtrans from "./pages/payment/PaymentMidtrans";
import { useAuthStore } from "./contexts/auth/auth.state";
import ReactPixel from "react-facebook-pixel";
import TrackingCodeHeaderComponent from "./components/TrackingCodeHeader.component";
import TrackingCodeFooterComponent from "./components/TrackignCodeFooter.component";
import PaymentV2 from "./pages/payment/PaymentV2";
import PaymentMidtransV2 from "./pages/payment/PaymentMidtransV2";
import PaymentIPayMu from "./pages/payment/PaymentIPaymu";
import PaymentIPayMuV2 from "./pages/payment/PaymentIPaymuV2";
import PaymentTopupV2 from "./pages/payment/PaymentTopupV2";
import PaymentMidtransTopupV2 from "./pages/payment/PaymentMidtransTopupV2";
import PaymentWalletV2 from "./pages/payment/PaymentWalletV2";
import PaymentNicepay from "./pages/payment/PaymentNicepay";
import PaymentNicepayV2 from "./pages/payment/PaymentNicepayV2";
import PaymentMidtransCommerce from "./pages/payment/PaymentMidtransCommerce";
import PaymentCart from "./pages/payment/PaymentCart";
import PaymentMidtransCart from "./pages/payment/PaymentMidtransCart";
import PaymentIPayMuToupV2 from "./pages/payment/PaymentIpaymuTopupV2";
import PaymentIfortepay from "./pages/payment/PaymentIfortepay";

function App() {
  const { getCompany, companyData } = useAuthStore();

  useEffect(() => {
    console.log(process.env.REACT_APP_APP_NAME);
    getCompany();
  }, []);

  useEffect(() => {
    if (companyData !== undefined) {
      ReactPixel.init(companyData?.data?.facebook_pixel); // facebookPixelId
      ReactPixel.pageView();
    }
  }, [companyData]);

  return (
    <div>
      <TrackingCodeHeaderComponent />
      <Affix offsetTop={0}>
        <div className="shadow">
          <PageHeader
            ghost={false}
            className="site-page-header"
            onBack={() => window.history.back()}
            title="Payment"
            backIcon={<ArrowLeftOutlined color="black" />}
          />
        </div>
      </Affix>
      <div className="mobile-wrapper">
        <Switch>
          <Route exact path="/" component={Channel} />
          <Route exact path="/payment" component={Payment} />
          <Route exact path="/payment-cart" component={PaymentCart} />
          <Route exact path="/payment-v2/:invoice_code" component={PaymentV2} />
          <Route
            exact
            path="/payment-topup-v2/:invoice_code"
            component={PaymentTopupV2}
          />
          <Route exact path="/payment-midtrans" component={PaymentMidtrans} />
          <Route
            exact
            path="/payment-midtrans-cart"
            component={PaymentMidtransCart}
          />
          <Route exact path="/payment-ipaymu" component={PaymentIPayMu} />
          <Route exact path="/payment-nicepay" component={PaymentNicepay} />
          <Route
            exact
            path="/payment-midtrans-v2/:invoice_code"
            component={PaymentMidtransV2}
          />
          <Route
            exact
            path="/payment-midtrans-commerce/:invoice_code"
            component={PaymentMidtransCommerce}
          />
          <Route
            exact
            path="/payment-midtrans-topup-v2/:invoice_code"
            component={PaymentMidtransTopupV2}
          />
          <Route
            exact
            path="/payment-ipaymu-v2/:invoice_code"
            component={PaymentIPayMuV2}
          />
          <Route
            exact
            path="/payment-ipaymu-topup-v2/:invoice_code"
            component={PaymentIPayMuToupV2}
          />
          <Route
            exact
            path="/payment-nicepay-v2/:invoice_code"
            component={PaymentNicepayV2}
          />
          <Route
            exact
            path="/payment-nicepay/:invoice_code"
            component={PaymentNicepay}
          />
          <Route
            exact
            path="/payment-wallet-v2/:invoice_code"
            component={PaymentWalletV2}
          />

          <Route
            exact
            path="/payment-ifortepay"
            component={PaymentIfortepay}
          />
        </Switch>
      </div>
      <TrackingCodeFooterComponent />
    </div>
  );
}

export default App;
