import { AxiosError } from "axios";
import Api from "../../api/Api";
import { CancelPaymentModel } from "../../models/CancelPaymentModel";
import { DoPaymentModel } from "../../models/DoPaymentModel";
import { GeneralModel } from "../../models/GeneralModel";
import { PaymentResultModel } from "../../models/PaymentResultModel";
import { TransactionModel } from "../../models/TransactionModel";
import {
  DonationSingleCartResultInterface,
  DonationSingleResultInterface,
} from "./interfaces/donation-single-result.interface";
import { IPaymuPaymentModel } from "./interfaces/ipaymu-body-request.interface";
import { IPayResponse } from "./interfaces/ipaymu-result.interface";
import { WalletDetalResultInterface } from "./interfaces/wallet-detail-result.interface";
import { BalanceWalletResultInterface } from "./interfaces/balance-wallet-result.interface";
import {
  NicepayPaymentModel,
  NicepayStatusModel,
} from "./interfaces/nicepay-body-request.interface";
import { NicepayResponse } from "./interfaces/nicepay-result.interface";
import { NicepayStatusResponseModel } from "../../models/CheckSatusNicepayModel";
import { TransactionByInvoiceCodeResult } from "./interfaces/transaction-by-invoice-code-result.interface";
import { IfortepayPaymentResult } from "./interfaces/ifortepay-result.interface";

export class PaymentProvider {
  private api: Api;

  constructor() {
    this.api = new Api();
  }

  decryptData = async (encrypt: string): Promise<TransactionModel> => {
    try {
      const response = await this.api.api.get<TransactionModel>(
        "/api/payment_decrypt",
        {
          params: {
            query: encrypt,
          },
        }
      );
      return Promise.resolve(response.data);
    } catch (e) {
      const response: TransactionModel = e.response.data;
      return Promise.reject(response);
    }
  };

  initPayment = async (
    donationId: string,
    paymentId: string,
    vaNumber: string
  ): Promise<GeneralModel> => {
    try {
      const response = await this.api.apiProgram.put<GeneralModel>(
        `/public/donation-payment`,
        {
          donation_id: donationId,
          payment_id: paymentId,
          va_number: vaNumber,
        }
      );
      return Promise.resolve(response.data);
    } catch (e) {
      const response: PaymentResultModel = e.response.data;
      return Promise.reject(response);
    }
  };

  doPayment = async (data: DoPaymentModel): Promise<PaymentResultModel> => {
    try {
      const response = await this.api.api.post<PaymentResultModel>(
        `/api/payment?company_id=${process.env.REACT_APP_COMPANY_ID}`,
        data
      );

      // save to localStorage for future use
      localStorage.setItem(
        data.transaction_details?.order_id!,
        JSON.stringify(response.data)
      );

      return Promise.resolve(response.data);
    } catch (e) {
      const response: PaymentResultModel = e.response.data;
      return Promise.reject(response);
    }
  };

  cancelPayment = async (orderId: string): Promise<CancelPaymentModel> => {
    try {
      const response = await this.api.api.get<CancelPaymentModel>(
        `/api/payment_cancel`,
        {
          params: {
            orderId: orderId,
            company_id: process.env.REACT_APP_COMPANY_ID,
          },
        }
      );
      return Promise.resolve(response.data);
    } catch (e) {
      const response: CancelPaymentModel = e.response.data;
      return Promise.reject(response);
    }
  };

  transactionStatus = async (orderId: string): Promise<CancelPaymentModel> => {
    try {
      const response = await this.api.api.get<any>(`/api/payment_status`, {
        params: {
          orderId: orderId,
          company_id: process.env.REACT_APP_COMPANY_ID,
        },
      });
      return Promise.resolve(response.data);
    } catch (e) {
      const response: CancelPaymentModel = e.response.data;
      return Promise.reject(response);
    }
  };

  transactionStatusCommerce = async (
    orderId: string
  ): Promise<PaymentResultModel> => {
    try {
      const response = await this.api.api.get<any>(
        `/api/payment_status_commerce`,
        {
          params: {
            orderId: orderId,
            company_id: process.env.REACT_APP_COMPANY_ID,
          },
        }
      );
      return Promise.resolve(response.data);
    } catch (e) {
      const response: CancelPaymentModel = e.response.data;
      return Promise.reject(response);
    }
  };

  getDonationDetail = async (
    invoiceCode: string
  ): Promise<DonationSingleResultInterface> => {
    try {
      let response = await this.api.apiProgram.get(
        `/public/donations-by-invoice-code/${invoiceCode}`
      );
      return response.data;
    } catch (e) {
      let a = e as AxiosError;
      let response: DonationSingleResultInterface = a.response?.data;
      return response;
    }
  };

  getDonationDetailCart = async (
    invoiceCode: string
  ): Promise<DonationSingleCartResultInterface> => {
    try {
      let response = await this.api.apiProgram.get(
        `/public/donations-by-invoice-code-cart/${invoiceCode}`
      );
      return response.data;
    } catch (e) {
      let a = e as AxiosError;
      let response: DonationSingleCartResultInterface = a.response?.data;
      return response;
    }
  };

  getTransactionDetail = async (
    invoiceCode: string
  ): Promise<TransactionByInvoiceCodeResult> => {
    try {
      let response = await this.api.apiMarketplace.get(
        `/public/transaction-by-invoice-code/${invoiceCode}`
      );
      return response.data;
    } catch (e) {
      let a = e as AxiosError;
      let response: TransactionByInvoiceCodeResult = a.response?.data;
      return response;
    }
  };

  initPaymentIPaymu = async (
    bodyReq: IPaymuPaymentModel
  ): Promise<IPayResponse> => {
    try {
      let response = await this.api.api.post<any>(
        `/api/payment_ipaymu?company_id=${process.env.REACT_APP_COMPANY_ID}`,
        {
          name: bodyReq.name,
          phone: bodyReq.phone,
          email: bodyReq.email,
          amount: bodyReq.amount,
          product: bodyReq.product,
          returnUrl: bodyReq.returnUrl,
          cancelUrl: bodyReq.cancelUrl,
          orderId: bodyReq.orderId,
          paymentMethod: bodyReq.paymentMethod,
          paymentChannel: bodyReq.paymentChannel,
          comments: bodyReq.comments,
        }
      );
      return response.data;
    } catch (e) {
      let a = e as AxiosError;
      let response: IPayResponse = a.response?.data;
      return response;
    }
  };

  initPaymentIfortepay = async (
    bodyReq: any
  ): Promise<IfortepayPaymentResult> => {
    try {
      let response = await this.api.api.post<any>(
        `/api/payment_ifortepay_payment_page`,
        bodyReq
      );
      return response.data as IfortepayPaymentResult;
    } catch (e) {
      let a = e as AxiosError;
      let response: IfortepayPaymentResult = a.response?.data;
      return response;
    }
  };

  initPaymentNicepay = async (
    bodyReq: NicepayPaymentModel
  ): Promise<NicepayResponse> => {
    try {
      let response = await this.api.api.post<any>(
        `/api/payment_nicepay?company_id=${process.env.REACT_APP_COMPANY_ID}`,
        {
          payMethod: bodyReq.payMethod,
          currency: bodyReq.currency,
          bankCd: bodyReq.bankCd,
          amt: bodyReq.amt,
          referenceNo: bodyReq.referenceNo,
          mitraCd: bodyReq,
          goodsNm: bodyReq.goodsNm,
          cartData: bodyReq.cartData,
          billingNm: bodyReq.billingNm,
          billingPhone: bodyReq.billingPhone,
          billingEmail: bodyReq.billingEmail,
          billingAddr: bodyReq.billingAddr,
          billingCity: bodyReq.billingCity,
          billingState: bodyReq.billingState,
          billingPostCd: bodyReq.billingPostCd,
          billingCountry: bodyReq.billingCountry,
          userSessionID: bodyReq.userSessionID,
        }
      );
      return response.data;
    } catch (e) {
      let a = e as AxiosError;
      let response: NicepayResponse = a.response?.data;
      return response;
    }
  };

  initStatusNicepay = async (
    bodyReq: NicepayStatusModel
  ): Promise<NicepayStatusResponseModel> => {
    try {
      let response = await this.api.api.post<any>(
        `/api/payment_check_status_nicepay?company_id=${process.env.REACT_APP_COMPANY_ID}`,
        {
          tXid: bodyReq.tXid,
          referenceNo: bodyReq.referenceNo,
          amt: bodyReq.amt,
        }
      );
      return response.data;
    } catch (e) {
      let a = e as AxiosError;
      let response: NicepayStatusResponseModel = a.response?.data;
      return response;
    }
  };

  checkWalletDetail = async (
    invoiceCode: string
  ): Promise<WalletDetalResultInterface> => {
    try {
      const response = await this.api.apiWallet.get(
        `/public/check-settle-wallet/${invoiceCode}`
      );
      return response.data;
    } catch (e) {
      let a = e as AxiosError;
      let response: WalletDetalResultInterface = a.response?.data;
      return response;
    }
  };

  balanceWalletUser = async (
    userId: string
  ): Promise<BalanceWalletResultInterface> => {
    try {
      const response = await this.api.apiWallet.get(
        `/public/total-wallet-user/${userId}`
      );
      return response.data;
    } catch (e) {
      let a = e as AxiosError;
      let response: BalanceWalletResultInterface = a.response?.data;
      return response;
    }
  };

  deductWallet = async (
    userId: string,
    donationId: string,
    donationAmount: number,
    description: string
  ): Promise<GeneralModel> => {
    try {
      const response = await this.api.apiWallet.post(`/public/deduct-wallet`, {
        user_id: userId,
        donation_id: donationId,
        donation_amount: donationAmount,
        description: description,
        company_id: process.env.REACT_APP_COMPANY_ID,
      });
      return response.data;
    } catch (e) {
      let a = e as AxiosError;
      let response: GeneralModel = a.response?.data;
      return response;
    }
  };

  uploadProofOfPayment = async (
    invoiceCode: string,
    proofOfPayment: string
  ): Promise<DonationSingleResultInterface> => {
    try {
      let response = await this.api.apiProgram.put(
        `/public/upload-proof-payment/${invoiceCode}`,
        {
          proof_of_payment: proofOfPayment,
        }
      );
      return response.data;
    } catch (e) {
      let a = e as AxiosError;
      let response: DonationSingleResultInterface = a.response?.data;
      return response;
    }
  };

  uploadProofOfPaymentCart = async (
    invoiceCode: string,
    proofOfPayment: string
  ): Promise<DonationSingleCartResultInterface> => {
    try {
      let response = await this.api.apiProgram.put(
        `/public/upload-proof-payment-cart/${invoiceCode}`,
        {
          proof_of_payment: proofOfPayment,
        }
      );
      return response.data;
    } catch (e) {
      let a = e as AxiosError;
      let response: DonationSingleCartResultInterface = a.response?.data;
      return response;
    }
  };
}
